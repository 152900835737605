body {
  text-align: center;
  overflow: scroll;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

img {
  border: 1px solid black;
  border-radius: 2% 2%;
}

.mainPic {
  border-radius: 25% 10%;
}

.us {
  border-radius: 5% 5%;
}

.content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: wrap
}

.bioContainer {
  margin: 16px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.bioHeader {
  text-decoration: underline;
}

.descBio {
  max-width: 700px;
  text-align: justify;
}

.familyHeader {
  text-decoration: underline;
}

.imagesContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.persons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.family_pics {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.vids {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.menuButton:hover {
  opacity: 0.75;
}

.appointmentFormContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.appointmentForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.formSubmission {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}